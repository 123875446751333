import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./SimplePage.scss";
import data from "../../all__news.json";
import { infoText } from "../../shared/shared";
import NewsBox from "../../components/NewsBox/NewsBox";

export default function SimplePage() {
  const [categoryNews, setCategoryNews] = useState(data);
  // console.log("Infotext", infoText);
  // console.log("Category", categoryNews);
  // const [allNews, setAllNews] = useState(() => {
  //   const publishedNews = [];
  //   data.forEach((x) => {
  //     if (x.published) {
  //       publishedNews.push(x);
  //     }
  //   });
  // });
  // console.log("AllNews", allNews);
  // let loc = useLocation();
  let { category, id } = useParams();
  // console.log("ID", category, id);
  let arr = ["o-nama", "marketing", "pravila-koriscenja", "politika-privatnosti"];
  const setPositionInArray = (arr, id) => {
    let z = arr.indexOf(id);
    // console.log("Z", z);
    return z;
  };

  const setClass = () => {
    if (window.innerWidth < 500) {
      return "boxWrapper box2 news3";
    } else {
      return "boxWrapper mainBox";
    }
  };

  const setNewsBoxes = (arr) => {
    return arr[1] ? <NewsBox val={arr} /> : <div>Loading...</div>;
  };

  return (
    <div className="simplePage">
      <h1>{infoText[setPositionInArray(arr, id)][0]}</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: infoText[setPositionInArray(arr, id)][1],
        }}
      ></p>
      {/* <h1>Najnovije vesti</h1>
      <section className="home__box__11">
        {setNewsBoxes([...categoryNews], 3, "home__box__2-layout", setClass())}
      </section> */}
    </div>
  );
}
