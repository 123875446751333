import React from "react";

const setTime = (postTime, classes) => {
  var months = [
    "januar",
    "februar",
    "mart",
    "april",
    "maj",
    "jun",
    "jul",
    "avgust",
    "septembar",
    "oktobar",
    "novembar",
    "decembar",
  ];
  var days = [
    "nedelja",
    "ponedeljak",
    "utorak",
    "sreda",
    "četvrtak",
    "petak",
    "subota",
  ];
  let e = new Date(postTime);
  let day = days[e.getDay()];
  let date = e.getDate();
  let month = months[e.getMonth()];
  let y = e.getFullYear();
  if (classes === "noDay") {
    return <>{" " + date + ". " + month}</>;
  } else {
    return <>{date + ". " + month + " " + y + "."}</>;
  }
};

export default function TimeFormat(props) {
  return <>{setTime(props.timePost, props.classes)}</>;
}
