import React, { useState, lazy, Suspense, useEffect } from "react";
import "./News.scss";
import Backdrop from "../../components/Bars/Backdrop/Backdrop";
import ArticalBox from "../../components/ArticalBox/ArticalBox";
import { useIdContext } from "../../shared/IdProvider";
import { Link } from "react-router-dom";

export default function News(props) {
  const { publishedNews } = useIdContext();
  const [filtered, setFiltered] = useState(publishedNews);
  const [allNewsCat1, setAllNewsCat1] = useState(artFilterFunc(publishedNews, "Voćarstvo"));
  const [allNewsCat2, setAllNewsCat2] = useState(artFilterFunc(publishedNews, "Subvencije"));
  const [allNewsCat3, setAllNewsCat3] = useState(artFilterFunc(publishedNews, "Organska proizvodnja"));
  const [allNewsCat4, setAllNewsCat4] = useState(artFilterFunc(publishedNews, "Ostalo"));
  const [allNewsCat5, setAllNewsCat5] = useState(artFilterFunc(publishedNews, "Manifestacije"));
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  function artFilterFunc(item, cat) {
    let arr = item.filter((i) => {
      return i.category === cat;
    });
    return arr;
  }

  const setNewsBoxes = (n, newsState, classDiv, classBox) => {
    return newsState ? (
      <div className={classDiv}>
        {newsState.slice(n[0], n[1]).map((news, index) => {
          return <ArticalBox key={index} n={news} classes={displayClassName(classBox, n[0], index)} />;
        })}
      </div>
    ) : (
      <div>Loading...</div>
    );
  };
  const displayClassName = (classes, n, index) => {
    if (window.innerWidth < 768 && n === 4 && index === 0) {
      return "boxWrapper mainBox box3 dark";
    } else {
      return classes;
    }
  };
  return (
    <div className="news">
      <div className="news-wrapper">
        <h1>Vesti</h1>
        <section className="home__box__1">
          <div className="home__box">
            <ArticalBox n={filtered[0]} classes="mainNews" />
          </div>
          {setNewsBoxes([1, 4], filtered, "home__rightBox", "boxWrapper box1")}
        </section>
        <section className="home__box__11">
          {setNewsBoxes([4, 7], filtered, "home__rightBox", "boxWrapper box2")}
          <div className="home__adBox"></div>
        </section>
        <section className="home__box__3">
          <h2>
            <Link to="/vesti/vocarstvo/">Voćarstvo</Link>
          </h2>
          {setNewsBoxes([0, 3], allNewsCat1, "home__box__3-wrapper", "boxWrapper mainBox box3 dark")}
        </section>
        <section className="home__box__2">
          <h2>
            <Link to="/vesti/subvencije/">Subvencije</Link>
          </h2>
          {setNewsBoxes([0, 3], allNewsCat2, "home__box__2-layout", "boxWrapper mainBox box3 white")}
        </section>
        <section className="home__box__71">
          <h2>
            <Link to="/vesti/organska-proizvodnja/">Organska proizvodnja</Link>
          </h2>
          <div className="home__box__71-wrapper">
            {setNewsBoxes([0, 3], allNewsCat3, "home__rightBox", "boxWrapper box2")}
            <div className="home__adBox"></div>
          </div>
        </section>
        <section className="home__box__3">
          <div className="home_3_title">
            <h2>
              <Link to="/vesti/manifestacije/">Manifestacije</Link>
            </h2>
          </div>
          {setNewsBoxes([0, 3], allNewsCat5, "home__box__3-wrapper", "boxWrapper mainBox box3 dark")}
        </section>
        <section className="home__box__71">
          <h2>
            <Link to="/vesti/ostalo/">Ostalo</Link>
          </h2>
          <div className="home__box__71-wrapper">
            {setNewsBoxes([0, 3], allNewsCat4, "home__rightBox", "boxWrapper box2")}
            <div className="home__adBox"></div>
          </div>
        </section>

        <Backdrop />
      </div>
    </div>
  );
}
