import React from "react";
import "./Pagination.scss";

export default function Pagination(props) {
  // const pages = Array.from({ length: props.numberOfPages }, (i, v) => v + 1);
  return (
    <div className="pagination" style={{ display: `${props.prikaz}` }}>
      <div onClick={openPage(props)} className={getClassName(props)}>
        Učitaj više +
      </div>
    </div>
  );
}
const getClassName = (props) => {
  return props.pageNum === props.numberOfPages ? "open" : "";
};

const openPage = (props, page) => {
  return () => {
    props.clicked(page);
  };
};
