import React, { useState, Suspense, lazy } from "react";
import "./ArticleBox.scss";
import { Link, useNavigate } from "react-router-dom";
import TimeFormat from "../TimeFormat/TimeFormat";
import { options } from "../../shared/shared";
import { urlTitle2 } from "../../shared/utillity";
const PostImage = lazy(() => import("../../containers/SinglePost/img/PostImage"));

export default function ArticalBox(props) {
  // const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [fallBackSrc, setFallBackSrc] = useState("/img/fallBack.jpg");
  const history = useNavigate();

  const setPreUrlTitle = (item, allC, cat) => {
    const test4 = allC.filter((e) => {
      return urlTitle2(e.title) === urlTitle2(item.category);
    })[0];
    if (cat) {
      return test4.route + "/";
    } else {
      return test4.route + "/" + urlTitle2(item.title) + "/";
    }
  };
  const articleData = props.n;
  const allCategories = [];
  options.forEach((item) => {
    if (item.item_list) {
      item.item_list.map((e) => {
        allCategories.push(e);
      });
    } else {
      allCategories.push(item);
    }
  });
  const imgSrc = !error ? articleData.pics[0] : fallBackSrc;
  const setTitle = articleData.title_2 ? articleData.title_2 : articleData.title;
  return (
    <div className={props.classes}>
      <div className="artBox-wrapper">
        <Link to={setPreUrlTitle(articleData, allCategories)} className="artBox-img">
          <Suspense fallback={<div></div>}>
            <PostImage src={imgSrc} alt={articleData.tags[0]} />
          </Suspense>
        </Link>
        <div className="artBox-desc">
          <Link to={setPreUrlTitle(articleData, allCategories)}>
            <h3>{setTitle}</h3>
          </Link>
          <div className="artBox-desc-other">
            <Link to={setPreUrlTitle(articleData, allCategories, true)}>{articleData.category}</Link>
            <span>
              |<TimeFormat timePost={articleData.time2} classes={"noDay"} />
            </span>
          </div>
          <span className="artBox-lead">{articleData.lead}...</span>
          <button
            classes="artBox-btn"
            onClick={() => {
              history(setPreUrlTitle(articleData, allCategories));
            }}
          >
            Nastavi čitanje
          </button>
        </div>
      </div>
    </div>
  );
}
